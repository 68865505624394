import { ThemeType } from '@share/enums';
import FooterLogo from '../images/footer-logo.jpg';
import HeaderLogo from '../images/header-logo.png';

const VectorLogo = undefined;

const defaultTheme = ThemeType.LIGHT;
const hasThemeSwitcher = false;
const hasNetworkSwitcher = true;
const hasWhatsappWidget = false;
const hasBanner = true;
const isDisableWalletLogin = false;

const headerLogoSize = 160;
const DarkHeaderLogo = HeaderLogo;
const DarkFooterLogo = FooterLogo;
const footerLogoSize = 160;

export {
  VectorLogo,
  HeaderLogo,
  DarkHeaderLogo,
  headerLogoSize,
  FooterLogo,
  DarkFooterLogo,
  footerLogoSize,
  defaultTheme,
  hasThemeSwitcher,
  hasNetworkSwitcher,
  hasWhatsappWidget,
  hasBanner,
  isDisableWalletLogin,
};
